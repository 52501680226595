import * as React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { isEcommerceState } from '@/recoil/isEcommerce'

import { wineType } from '@/model/wine'
import { roundPrice } from '@/utils/price'

import {
  Container,
  Wrapper,
  Art,
  Image,
  Content,
  Name,
  Price,
  PriceValue
} from './style'

const WinesSliderItem = ({ item, size = 'medium' }) => {
  const isEcommerce = useRecoilValue(isEcommerceState)

  return (
    <Container to={ item.path } title={ `See more about ${item.name}` }>
      <Wrapper className={ size } colorBg={ item.colorBg }>
        <Art src={ resolveAssetURL(item.art) } loading='lazy' alt={ `Bottle of ${item.name}` } />
        <Image src={ resolveAssetURL(item.img) } loading='lazy' alt={ `Bottle of ${item.name}` } />
      </Wrapper>
      <Content>
        <Name className={ `${isEcommerce && 'hasPrice'}` }>
          { item.name }
        </Name>
        {isEcommerce && (
          <Price>
            from <PriceValue>${ roundPrice(item.price, 2) }</PriceValue>
          </Price>
        )}
      </Content>
    </Container>
  )
}

WinesSliderItem.propTypes = {
  item: wineType.isRequired,
  displayPrice: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
}

export default WinesSliderItem
