import React, { useState, useRef, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { graphql, useStaticQuery } from 'gatsby'
import WinesSliderItem from '@/components/WinesSlider/WinesSliderItem'
import RoundedImageWithLabel from '@/components/RoundedImageWithLabel'
import wineFinderImage from '@/images/subnav-wine-finder.png'
import { subNavHeightState } from '@/recoil/header'
import PropTypes from 'prop-types'
import { HEADER_CLICK } from '@/utils/gtmEvents'
import BackBtn from '@/components/Header/BackBtn'

import { isMobileState } from '@/recoil/layout'
import { marketCodeManager } from '@/recoil/marketCode'

import {
  Container,
  StyledGridContainer,
  TextColumn,
  WinesListColumn,
  SelectedWineColumn,
  WinesListItem,
  AllWinesLink,
  Title,
  Text,
  Button,
  Header,
  WineItemWrapper,
  ButtonMobile
} from './style'

const OurWinesSubnav = ({ backFunc = () => {} }) => {
  const data = useStaticQuery(graphql`
    query OurWinesSubnavQuery {
      drupal {
        ourWineQuery: nodeById(id: "296") {
          ... on Drupal_NodeMegamenu {
            nid
            fieldMegamenuItems {
              entity {
                id
                ... on Drupal_ParagraphMenuItemOurWines {
                  id
                  fieldMenuOurWinesMarkets {
                    entity {
                      ... on Drupal_TaxonomyTermCountry {
                        langcode {
                          value
                        }
                        fieldCountryCode
                      }
                    }
                  }
                  fieldMenuItemOurWinesIntro
                  fieldMenuItemOurWinesTitle
                  fieldMenuItemOurWinesCta {
                    title
                    url {
                      path
                    }
                  }
                }
              }
            }
          }
        }
        winesListQuery: taxonomyTermQuery(limit: 1000, offset: 0, sort: [{field: "weight", direction: ASC}], filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}) {
          entities {
            ... on Drupal_TaxonomyTermWineCategory {
              tid
              name
              fieldWineCategorySlugUrl
              fieldWineCatPrimaryVintage {
                entity {
                  ...on Drupal_NodeWineProduct {
                    nid
                    path {
                      alias
                    }
                    title
                    fieldProductDiscoverColorCta
                    fieldWineProductBackcolor
                    fieldWineProductBackillustrat {
                      url
                    }
                    fieldWineProductShortDesc
                    fieldWineProductName
                    fieldCategoryProductName
                    fieldWineProductDiscoverCta {
                      title
                    }
                    fieldWineProductPackshot {
                      url
                    }
                  }
                }
              }
              fieldWineCategoryFromPrice
            }
          }
        }
      }
    }
  `)

  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeManager)
  const [selectedWine, setSelectedWine] = useState(null)
  const containerRef = useRef(null)
  const setSubNavHeight = useSetRecoilState(subNavHeightState)
  const ourWineData = data.drupal.ourWineQuery.fieldMegamenuItems.find((item) => {
    let markets = []
    const { entity } = item

    if (entity.fieldMenuOurWinesMarkets) {
      markets = entity.fieldMenuOurWinesMarkets.map(market => `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase())
    }

    return markets.includes(marketCode)
  })

  const gtm = (name) => {
    window.dataLayer && window.dataLayer.push({
      event: HEADER_CLICK,
      cta_name: name
    })
  }

  useEffect(() => {
    if (containerRef.current) {
      setSubNavHeight(Math.round(containerRef.current.getBoundingClientRect().height))
    }
    return () => setSubNavHeight(0)
  }, [containerRef, selectedWine])

  return (
    <Container ref={ containerRef } className='subnav'>
      <StyledGridContainer>
        <Header>
          <BackBtn onClick={ backFunc } label='back' />
        </Header>
        <TextColumn>
          <Title>{ ourWineData?.entity?.fieldMenuItemOurWinesTitle }</Title>
          <Text>{ ourWineData?.entity?.fieldMenuItemOurWinesIntro }</Text>
          <Button to='/our-wines' target='_self' title='Discover all the wines from the two Cloudy Bay wineries'>
            { ourWineData?.entity?.fieldMenuItemOurWinesCta?.title }
          </Button>
        </TextColumn>
        <WinesListColumn onMouseLeave={ () => setSelectedWine(null) }>
          {
            data.drupal.winesListQuery.entities.map((category, i) => {
              const item = {
                id: category,
                img: category.fieldWineCatPrimaryVintage.entity.fieldWineProductPackshot.url,
                art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
                name: category.name,
                price: category.fieldWineCategoryFromPrice,
                colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
                colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
                title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
                text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
                path: category.fieldWineCatPrimaryVintage.entity.path.alias
              }

              return (
                <WinesListItem
                  to={ category.fieldWineCatPrimaryVintage.entity.path.alias }
                  key={ `item-${i}` }
                  title={ `See more about ${category.name}` }
                  onMouseOver={ () => setSelectedWine(item) }
                  active={ selectedWine && item.id === selectedWine.id }
                  onClick={ () => gtm(`Our Wines > ${category.name}`) }
                >
                  <span>{ category.name }</span>
                </WinesListItem>
              )
            })
          }
          <AllWinesLink
            to={ isMobile && marketCode === 'en-nz' ? '/other-products' : '/our-wines' }
            onMouseOver={ () => setSelectedWine(null) }
            onClick={ () => gtm('Our Wines > All Wines') }
            title='Discover all the wines from the two Cloudy Bay wineries'
          >
            {isMobile && marketCode === 'en-nz' ? 'Other Wines' : 'See all our wines' }
          </AllWinesLink>
        </WinesListColumn>
        <ButtonMobile to='/our-wines'>Discover our wines</ButtonMobile>
        <SelectedWineColumn>
          { !selectedWine && (
            <RoundedImageWithLabel
              image={ wineFinderImage }
              imageSize='small'
              to='/wine-finder'
              title='Find your soul mate'
              subtitle='Wine Finder'
            />
          )}
          { selectedWine && (
            <WineItemWrapper>
              <WinesSliderItem item={ selectedWine } displayPrice={ false } size='small' />
            </WineItemWrapper>
          )}
        </SelectedWineColumn>
      </StyledGridContainer>
    </Container>
  )
}

OurWinesSubnav.propTypes = {
  backFunc: PropTypes.func
}

export default OurWinesSubnav
