import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { Transition } from 'react-transition-group'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { loadingState } from '@/recoil/loader'
import { isMobileState, isTabletState } from '@/recoil/layout'
import { isTouchDeviceState } from '@/recoil/device'

import { hasValidAgeManager } from '@/recoil/customer'
import {
  fallbackMarketCode,
  marketCodeManager,
  marketCodesListState,
  ecommerceMarketCodesListState
} from '@/recoil/marketCode'

import breakpoints from '@/styles/breakpoints'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NewslettterPopup from '@/components/Popup/NewsletterPopup'
import NewslettterMsgPopup from '@/components/Popup/NewsletterMsgPopup'

import Loader from '@/components/Loader'
import AgeGate from '@/components/AgeGate'

// POPUPS
import TeamPopup from '@/components/Popup/TeamPopup'
import MapPopup from '@/components/Popup/MapPopup'
import TastingPopup from '@/components/Popup/TastingPopup'
import EventPopup from '@/components/Popup/EventPopup'
import VideoPopup from '@/components/Popup/VideoPopup'
import StoreLocatorPopup from '@/components/Popup/StoreLocatorPopup'
import HTMLPopup from '@/components/Popup/HTMLPopup'
import SustainabilityPopup from '@/components/Popup/SustainabilityPopup'

import HrefLangs from '@/components/HrefLangs'

import { LoaderWrapper, AgeGateWrapper, Wrapper, Container } from './style'
import { MarketCodeContext } from '@/context/MarketContext'

const Default = ({
  data,
  children,
  entityBundle,
  footer = true,
  smallFooter = false,
  header = true,
  requireAgeGate = true,
  path = '',
  plainHeader = false,
  noPadding = false,
  pageType = 'Others',
  pageMarketCode = fallbackMarketCode,
  contentType = null,
  hrefLangsLocales = ['en-us', 'en-au', 'en-nz', 'en-gb', 'en-ww'],
  is404 = false
}) => {
  const [hasLayer, setHasLayer] = useState(false)
  const isLoading = useRecoilValue(loadingState)
  const setIsLoadingState = useSetRecoilState(loadingState)
  const hasValidAge = useRecoilValue(hasValidAgeManager)
  const cookieRef = React.useRef(null)
  const setIsMobileState = useSetRecoilState(isMobileState)
  const isMobile = useRecoilValue(isMobileState)
  const setIsTabletState = useSetRecoilState(isTabletState)
  const setIsTouchDevice = useSetRecoilState(isTouchDeviceState)
  const setMarketCode = useSetRecoilState(marketCodeManager)
  const marketCode = useRecoilValue(marketCodeManager)
  const [marketCodesList, setMarketCodesList] =
    useRecoilState(marketCodesListState)
  const setEcommerceMarketCodesList = useSetRecoilState(
    ecommerceMarketCodesListState
  )
  const ecommerceMarketCodesList = useRecoilValue(
    ecommerceMarketCodesListState
  )
  const location = useLocation()

  const marketCodes = useStaticQuery(
    graphql`
      query MarketCodes {
        drupal {
          marketsQuery: taxonomyTermQuery(
            limit: 1000
            offset: 0
            filter: {
              conditions: [
                { operator: EQUAL, field: "vid", value: ["country"] }
              ]
            }
          ) {
            entities {
              ... on Drupal_TaxonomyTermCountry {
                langcode {
                  value
                }
                fieldCountryCode
                fieldCountryPriceDisplay
              }
            }
          }
          nodeQuery(
            limit: 1000
            offset: 0
            filter: {
              conditions: {
                field: "type",
                value: ["about_us", "wine_club", "home_page", "wine_product", "cellar_door", "experience", "events", "journal", "news", "article", "modular_page", "other_products", "wine_finder", "careers"]
                }
              }
          ) {
            count
            entities {
              entityBundle
              entityUrl {
                path
              }
              entityId
              ... on Drupal_NodeAboutUs {
                fieldCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldAboutUsMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeCareers {
                fieldCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldCareersMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeCellarDoor {
                fieldCellarDoorCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldCellarDoorMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeEvents {
                fieldEventCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldEventsMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeExperience {
                fieldExperienceCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldExperienceMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeHomePage {
                fieldHomeCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldHomeMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeJournal {
                fieldJournalCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldJournalMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeModularPage {
                fieldModularPageMarkets {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldModularPageMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeNews {
                fieldEventMarkets {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldNewsMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeOtherProducts {
                fieldWineProductCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldWineProductMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeArticle {
                fieldStaticPageCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldStaticPageMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeWineClub {
                fieldWineClubCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldWineClubMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeWineFinder {
                fieldWineFinderMarkets {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldWineFinderMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Drupal_NodeWineProduct {
                fieldWineProductCountry {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldWineProductMetaTags {
                  entity {
                    ... on Drupal_ParagraphMetaTags {
                      fieldRelatedPages {
                        entity {
                          ... on Drupal_ParagraphPageMarketRelationship {
                            fieldMarketPages {
                              entity {
                                entityUrl {
                                  path
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  //
  // const marketCodes = useStaticQuery(
  //   graphql`
  //     query MarketCodes {
  //       drupal {
  //         marketsQuery: taxonomyTermQuery(
  //           limit: 1000
  //           offset: 0
  //           filter: {
  //             conditions: [
  //               { operator: EQUAL, field: "vid", value: ["country"] }
  //             ]
  //           }
  //         ) {
  //           entities {
  //             ... on Drupal_TaxonomyTermCountry {
  //               langcode {
  //                 value
  //               }
  //               fieldCountryCode
  //               fieldCountryPriceDisplay
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setIsLoadingState(false)
      }, 1000)
    }
  }, [isMobile]);

  const marketCodesData = marketCodes.drupal.marketsQuery.entities.map(
    (market) => ({
      code: `${market.langcode.value}-${market.fieldCountryCode}`.toLowerCase(),
      priceDisplay: !!market.fieldCountryPriceDisplay
    })
  )

  useEffect(() => {
    setMarketCodesList(marketCodesData.map((marketCode) => marketCode.code))
    setEcommerceMarketCodesList(
      marketCodesData
        .filter((marketCode) => marketCode.priceDisplay)
        .map((marketCode) => marketCode.code)
    )
  }, [])

  const gtm = () => {
    // gtm
    const pagePath = location.pathname.split('/').splice(2)

    let event = {
      country: location.pathname.split('/')[1].slice(3).toUpperCase(),
      page_type: pageType,
      page_top_category: pageType
    }

    if (pagePath[0] !== undefined) {
      event = {
        ...event,
        page_top_category: pageType === 'Home' ? 'Home' : pagePath[0]
      }
    }
    if (pagePath[1] !== undefined) { event = { ...event, page_mid_category: pagePath[1] } }


    if (!!contentType && !!contentType.length && pagePath[0] === 'journal') {
      event = { ...event, content_type: contentType }
    }

    if (
      typeof window !== 'undefined' &&
      window.dataLayer &&
      (dataLayer[dataLayer.length - 1].country !== event.country ||
        dataLayer[dataLayer.length - 1].page_type !== event.page_type)
    ) {
      window.dataLayer.push(event)
    }
  }

  useEffect(() => {
    gtm()
  }, [marketCode, pageType])

  const onResize = () => {
    if (window) {
      const w = window.innerWidth
      const { sm, md, xs } = breakpoints
      setIsMobileState(w < xs)
      setIsTabletState(w > sm && w < md)
    }
  }

  const detectTouchDevice = () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
  }

  useEffect(() => {
    setHasLayer(isLoading || (!hasValidAge && requireAgeGate))
    setIsTouchDevice(detectTouchDevice())
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [isLoading, hasValidAge, requireAgeGate])

  useEffect(() => {
    if (!isLoading) {
      if (window?.location?.hash && window?.location?.hash.length) {
        const el = document.querySelector(window?.location?.hash)

        if (el) {
          const { height } = el.getBoundingClientRect()

          if (!location.hash) {
            window.scrollTo(0, el.offsetTop - (window.innerHeight - height) / 2)
          }
        }
      }
    }
  }, [isLoading])

  // const getRelatedPagesField = (entity) => {
  //   let field

  //   switch (entity) {
  //     case 'about_us':
  //       field = 'fieldAboutUsMetaTags'
  //       break;
  //     case 'article':
  //       field = 'fieldStaticPageMetaTags'
  //       break;
  //     case 'careers':
  //       field = 'fieldCareersMetaTags'
  //       break;
  //     case 'cellar_doors':
  //       field = 'fieldCellarDoorMetaTags'
  //       break;
  //     case 'events':
  //       field = 'fieldEventsMetaTags'
  //       break;
  //     case 'experience':
  //       field = 'fieldExperienceMetaTags'
  //       break;
  //     case 'home':
  //       field = 'fieldHomeMetaTags'
  //       break;
  //     case 'journal':
  //       field = 'fieldJournalMetaTags'
  //       break;
  //     case 'modular_page':
  //       field = 'fieldModularPageMetaTags'
  //       break;
  //     case 'news':
  //       field = 'fieldNewsMetaTags'
  //       break;
  //     case 'other_products':
  //       field = 'fieldWineProductMetaTags'
  //       break;
  //     case 'wine_club':
  //       field = 'fieldWineClubMetaTags'
  //       break;
  //     case 'wine_club':
  //       field = 'fieldWineClubMetaTags'
  //       break;
  //   }

  //   return field
  // }

  // const getRelatedPages = (market) => {
  //   let pages = []

  //   marketCodes?.drupal?.nodeQuery?.entities.map((item) => {
  //     const list = item[getRelatedPagesField(item.entityBundle)]?.entity?.fieldRelatedPages?.entity?.fieldMarketPages
  //     list?.map((page) => {
  //       if(page?.entity?.entityUrl?.path+'/' === location.pathname) {
  //         list.map((page) => pages.push(page?.entity?.entityUrl?.path))
  //         return
  //       }
  //     })
  //   })

  //   return pages[pages.findIndex(p => p.includes(marketCode))]
  // }

  const getAllUrls = () => {
    const COUNTRY_FIELDS_MAP = {
      about_us: 'fieldCountry',
      careers: 'fieldCountry',
      cellar_door: 'fieldCellarDoorCountry',
      events: 'fieldEventCountry',
      experience: 'fieldExperienceCountry',
      home_page: 'fieldHomeCountry',
      journal: 'fieldJournalCountry',
      modular_page: 'fieldModularPageMarkets',
      news: 'fieldEventMarkets',
      other_products: 'fieldWineProductCountry',
      article: 'fieldStaticPageCountry',
      wine_club: 'fieldWineClubCountry',
      wine_finder: 'fieldWineFinderMarkets',
      wine_product: 'fieldWineProductCountry',
    }

    const ALL_PAGES = marketCodes.drupal.nodeQuery.entities

    const ALL_URL = []
    const ALL_URL_BY_BUNDLE = []

    ALL_PAGES.forEach((p) => {
      if (p) {
        const pBundle = p.entityBundle
        const pMarkets = p[COUNTRY_FIELDS_MAP[pBundle]]
        const pPath = p.entityUrl.path

        pMarkets.forEach((m) => {
          const mCode = m.entity.fieldCountryCode.toLowerCase()
          const mLang = m.entity.langcode.value.toLowerCase()
          const mPrefix = `${mLang}-${mCode}`

          const fPath = (pPath.includes(mPrefix)) ? `${pPath}/` : `/${mPrefix}${pPath}/`

          ALL_URL.push(fPath)
          ALL_URL_BY_BUNDLE.push({ path: fPath, bundle: pBundle })
        })
      }
    })

    return {
      urls: ALL_URL,
      bundle: ALL_URL_BY_BUNDLE
    }
  }

  const getBundleRedirection = (bundle, marketCode) => {
    switch (bundle) {
      case 'news':
        return `/${marketCode}/journal/`;
        break
      case 'events':
        return `/${marketCode}/journal/`;
        break
      case 'experience':
        // TMP
        return `/${marketCode}/`;
        break
      case 'home_page':
        return `/${marketCode}/`;
        break
      case 'journal':
        return `/${marketCode}/journal/`;
        break
      case 'modular_page':
        return `/${marketCode}/`;
        break
      case 'other_products':
        return `/${marketCode}/other-products/`;
        break
      case 'about_us':
        return `/${marketCode}/about-us/`;
        break
      case 'wine_product':
        return `/${marketCode}/our-wines/`;
        break
      case 'article':
        return `/${marketCode}/`;
        break
      case 'cellar_door':
        return `/${marketCode}/`;
        break
      case 'wine_club':
        return `/${marketCode}/`;
        break
      case 'wine_finder':
        return `/${marketCode}/`;
        break
    }
  }

  useEffect(() => {
    const splittedPathname = location.pathname.split('/')
    const marketCodeFromPathname = splittedPathname[1]

    const redirect = getAllUrls()
    const baseUrl = marketCodeFromPathname === '' ? window.location.origin + '/' : window.location.origin

    if (!is404) {
      const currPath = window.location.pathname
      const nextPath = currPath.replace(marketCodeFromPathname, marketCode)
      const condStatic = !entityBundle
      const condBundle = !!entityBundle && entityBundle.length
      const cond = (condStatic || condBundle)

      if(hasValidAge && requireAgeGate && marketCodeFromPathname !== marketCode && cond) {

        if (condStatic) {
          window.location = nextPath
        }

        if (condBundle) {
          const check = redirect.urls.find(u => u.includes(nextPath))

          if (check) {
            window.location = `${baseUrl}${nextPath}`
          } else {
            window.location = `${baseUrl}${getBundleRedirection(entityBundle, marketCode)}`
          }
        }

        // window.location = `${baseUrl}${window.location.pathname.replace(marketCodeFromPathname, marketCode)}`
        return
        // const relatedPage = getRelatedPages(marketCode)
        // if(relatedPage) {
        //   window.history.replaceState(null, document.title, relatedPage)
        //   return
        // }
        // else {
        //   window.history.replaceState(null, document.title, '/')
        //   return
        // }
      } else {
        if (marketCodesList.includes(marketCodeFromPathname)) {
          setMarketCode(marketCodeFromPathname)
        }
      }

      if (!location.pathname.endsWith('/')) {
        const url =
          window.location.protocol +
          '//' +
          location.host +
          location.pathname +
          '/' +
          location.search

        if (window) {
          window.history.replaceState(null, document.title, url)
        }
      }
    } else {
      const params = (new URL(window.location)).searchParams;
      const currPath = params.get('q')
      const nextPath = currPath.replace(marketCodeFromPathname, marketCode)

      // REDIRECT TO PARENT ON 404 CONTEXT (case-sensitive)
      let parentPath = null

      if (nextPath.includes(`${marketCode}/our-wines/`))
        parentPath = `${marketCode}/our-wines/`

      if (nextPath.includes(`${marketCode}/journal/`))
        parentPath = `${marketCode}/journal/`

      if (nextPath.includes(`${marketCode}/events/`))
        parentPath = `${marketCode}/journal/`

      if (nextPath.includes(`${marketCode}/visit-us/marlborough`))
        parentPath = `${marketCode}/visit-us/marlborough/`

      if (nextPath.includes(`${marketCode}/visit-us/central-otago`))
        parentPath = `${marketCode}/visit-us/central-otago`

      if (parentPath)
        window.location = `${baseUrl}/${parentPath}`
    }
  }, [location, marketCodesList])

  return (
    <MarketCodeContext.Provider value={pageMarketCode} >
      <Container
        className={ cn({
          hasLayer,
          disableScroll: isLoading || (!hasValidAge && requireAgeGate)
        }) }
      >
        { hrefLangsLocales && (
          <HrefLangs path={ path } locales={ hrefLangsLocales } />
        )}
        {!isMobile && (
          <Transition in={ isLoading } timeout={ 500 } unmountOnExit mountOnEnter>
            {(state) => (
              <LoaderWrapper state={ state }>
                <Loader />
              </LoaderWrapper>
            )}
          </Transition>
        )}
        {/* <CookiePopUp /> */}
        <Transition
          in={ !isLoading && !hasValidAge && requireAgeGate }
          timeout={ 0 }
          unmountOnExit
          mountOnEnter
        >
          {(state) => (
            <AgeGateWrapper state={ state }>
              <AgeGate
                onSuccess={() => gtm()}
              />
            </AgeGateWrapper>
          )}
        </Transition>
        {header && <Header pageType={ pageType } forcePlainStyle={ plainHeader } />}
        <Wrapper padding={ noPadding } plainHeader={ plainHeader ? 'plain' : '' }>
          {children}
        </Wrapper>

        {/* POPUPS */}
        <TeamPopup />
        <MapPopup />
        {/* <TastingPopup
          title='See tasting notes'
          subtitle='Download PDF'
          text='Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br /><br />Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.<br /><br />Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.'
          image='/images/Product/map_1.png'
          actionLabel='Download PDF'
          actionUrl='/images/Product/map_1.png'
        /> */}
        <EventPopup />
        <VideoPopup />
        <StoreLocatorPopup />
        <NewslettterPopup />
        <HTMLPopup />
        <SustainabilityPopup />
        <NewslettterMsgPopup />

        {/* END POPUPS */}
        {/* ONE-TRUST COOKIES SETTINGS */}
        <button
          style={ { display: 'none' } }
          ref={ cookieRef }
          id='ot-sdk-btn'
          className='ot-sdk-show-settings'
        >
          Cookie Settings
        </button>
        {footer && (
          <Footer
            smallFooter={ smallFooter }
            className={ smallFooter ? 'smallFooter' : '' }
          />
        )}
      </Container>
    </MarketCodeContext.Provider>
  )
}

// export const pageQuery = graphql`
//   query DefaultQuery{
//     drupal {
//       marketsQuery: taxonomyTermQuery(
//         limit: 1000
//         offset: 0
//         filter: {conditions: [{operator: EQUAL, field: "vid", value: ["country"]}]}
//       ) {
//         entities {
//           ... on Drupal_TaxonomyTermCountry {
//             langcode {
//               value
//             }
//             fieldCountryCode
//             fieldCountryPriceDisplay
//           }
//         }
//       }
//       nodeById(id: "296") {
//         ... on NodeMegamenu {
//           title
//           fieldMegamenuItems {
//             entity {
//               ... on ParagraphMenuItemOurWines {
//                 id
//                 fieldMenuItemOurWinesTitle
//                 fieldMenuItemOurWinesIntro
//                 fieldMenuItemOurWinesCta {
//                   title
//                   url {
//                     path
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default Default
