import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  transition: opacity ease 700ms;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

export const CookieContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: transform ease 700ms;
`

export const CookieWrapper = styled(LoaderWrapper)`
  position: fixed;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.black}60;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};

  ${CookieContent} {
    transform: translateY(${({ state }) => (state === 'entered' ? 0 : '100%')});
  }
`

export const AgeGateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  transition: opacity ease 300ms;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

export const Wrapper = styled.div`
  /* do not put overflow: hidden, it will break the sticky sections nav bar */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  scroll-behavior: smooth;
  padding-top: ${({ plainHeader, padding, theme }) => plainHeader === 'plain' && !padding ? theme.header.normal : '0'}px;

`

export const Container = styled.div`

  &.hasLayer {
    height: 100vh;
  }

  /* &.disableScroll {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  } */

  .smallFooter{
    position: fixed;
    bottom: 0;
    left: 0;
    ${mediaMax.sm}{
      position: static;
    }
  }
`
